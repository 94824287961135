import { create } from 'zustand';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const useUserStore = create((set) => ({
    token: null,
    data: null,
    login: async (values) => {
        try {
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/user/login',
                method: 'POST',
                withCredentials: true,
                data: values
            };

            const res = await axios(config);
            if (res.data.ok) {
                set((state) => ({
                    ...state,
                    token: res.data.token,
                    data: res.data.data
                }));
                localStorage.setItem('user', res.data.user);
            }
            return res;
        } catch (error) {
            console.log(error);
            return error;
        }
    },
    getToken: () => {
        return useUserStore.getState().token;
    },
    isTokenExpired: () => {
        const token = useUserStore.getState().token;
        if (token) {
            const decodedToken = jwt_decode(token);
            return decodedToken.exp * 1000 < Date.now();
        }
        return true;
    },
    refreshToken: async () => {
        try {
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/user/refreshToken',
                method: 'GET',
                withCredentials: true
            };
            const res = await axios(config);
            set((state) => ({
                ...state,
                token: res.data.token,
                data: res.data.data
            }));
            return res;
        } catch (error) {
            console.log(error);
            window.location.replace('/auth/login');
        }
    },
    logout: async () => {
        try {
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/user/logout',
                method: 'GET',
                withCredentials: true
            };
            const res = await axios(config);
            if (res.data.ok) {
                localStorage.removeItem('user');
            }
            return await axios(config);
        } catch (error) {
            console.log(error);
        }
    }
}));
