// action - state management
import * as actionTypes from './actions';

export const initialState = {
    fname: '',
    lname: '',
    email: '',
    rol: '',
    panel: {
        companies: null,
        validation: null,
        blacklist: null,
        shop: null
    },
    _id: ''
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA:
            localStorage.setItem('user', JSON.stringify(action.data));
            localStorage.setItem('token', action.token);
            return {
                ...state,
                ...action.data,
                token: action.token
            };
        case actionTypes.HYDRATE_DATA:
            return {
                ...state,
                ...action.data,
                token: action.token
            };
        case actionTypes.DELETE_DATA:
            console.log(action);
            console.log('Entró el delete data');
            return {
                ...state,
                fname: '',
                lname: '',
                email: '',
                rol: '',
                panel: {
                    companies: null,
                    validation: null,
                    blacklist: null,
                    shop: null
                },
                _id: ''
            };
        default:
            return state;
    }
};

export default userReducer;
