/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import CFDIUse from './CFDIuse';
import Regime from './Regime';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import ListItem from '@mui/material/ListItem';
// import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ dialog, handleClose }) {
    const context = () => {
        let content = {
            title: '',
            body: null
        };
        switch (dialog.context) {
            case 'regime':
                content.title = 'Regimen Fiscal';
                content.body = <Regime />;
                break;
            case 'cfdiUse':
                content.title = 'Uso de CFDI';
                content.body = <CFDIUse />;
                break;
            default:
                break;
        }
        return content;
    };

    return (
        <div>
            <Dialog fullScreen open={dialog.open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton> */}
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h2" color="inherit">
                            {context().title}
                        </Typography>
                        <Button color="inherit" onClick={handleClose} size="large">
                            Listo
                        </Button>
                    </Toolbar>
                </AppBar>
                {context().body}
            </Dialog>
        </div>
    );
}
