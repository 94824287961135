import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

//Rest
const ErrorPage = Loadable(lazy(() => import('views/notFound/errorPage')));

// ==============================|| MAIN ROUTING ||============================== //

const MaintenanceRoutes = {
    path: '*',
    element: <ErrorPage />
};

export default MaintenanceRoutes;
