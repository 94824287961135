// assets
import { IconBrandChrome, IconHelp, IconFileTime } from '@tabler/icons';
import { MdBusinessCenter, MdOutlineShoppingCart } from 'react-icons/md';
import { GrValidate } from 'react-icons/gr';
import { IoMdListBox } from 'react-icons/io';

// constant
const icons = { IconBrandChrome, IconHelp, MdBusinessCenter, GrValidate, MdOutlineShoppingCart, IoMdListBox, IconFileTime };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        // {
        //     id: 'sample-page',
        //     title: 'Sample Page',
        //     type: 'item',
        //     url: '/sample-page',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // },
        {
            id: 'expirations',
            title: 'Vigencias',
            type: 'item',
            url: '/vigencias',
            icon: icons.IconFileTime,
            breadcrumbs: false
        },
        {
            id: 'companies',
            title: 'Empresas',
            type: 'item',
            url: '/empresas',
            icon: icons.MdBusinessCenter,
            breadcrumbs: false
        },
        {
            id: 'cfdi',
            title: 'Validar CFDI',
            type: 'item',
            url: '/validacion-cfdi',
            icon: icons.GrValidate,
            breadcrumbs: false
        }
        // {
        //     id: 'blacklist',
        //     title: 'Art. 69 B C.F.F',
        //     type: 'item',
        //     url: '/lista-negra',
        //     icon: icons.IoMdListBox,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'shop',
        //     title: 'Compras',
        //     type: 'item',
        //     url: '/compras',
        //     icon: icons.MdOutlineShoppingCart,
        //     breadcrumbs: false
        // }
    ]
};

export default other;
