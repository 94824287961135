import { Container, useTheme } from '@mui/material';
import MUIDataTable from 'mui-datatables';

const Table = ({ title, data, columns, options, contained = true }) => {
    const theme = useTheme();

    return contained ? (
        <Container
            sx={{
                width: '100%',
                [theme.breakpoints.down('md')]: {
                    width: '100%'
                },
                height: '100%',
                mt: '5%'
            }}
        >
            <MUIDataTable title={title} data={data} columns={columns} options={options} />
        </Container>
    ) : (
        <MUIDataTable title={title} data={data} columns={columns} options={options} />
    );
};

export default Table;
