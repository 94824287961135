import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table';
import Button from '@mui/material/Button';
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Chip,
    Container,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { useUserStore } from '../../../../store/userStore';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import CAD from '../../../../components/CAD';
import Modal from '../../../../components/Modal';
import CircularProgressWithLabel from '../../../../components/CircularProgress';
import file from '../../../../assets/formats/Formato Uso del CFDI.xlsx';

const CFDIUse = () => {
    const { refreshToken, isTokenExpired, getToken } = useUserStore();
    const theme = useTheme();

    //Circular Progress indicator
    const [progress, setProgress] = useState(0);

    // Table configuration
    const columns = [
        {
            name: '_id',
            options: {
                display: false
            }
        },
        {
            name: 'code',
            label: 'Código'
        },
        {
            name: 'desc',
            label: 'Descripción'
        },
        {
            name: 'physical',
            label: 'Física',
            options: {
                customBodyRender: (value) => {
                    return value ? 'SI' : 'NO';
                }
            }
        },
        {
            name: 'moral',
            label: 'Moral',
            options: {
                customBodyRender: (value) => {
                    return value ? 'SI' : 'NO';
                }
            }
        },
        {
            name: 'regimes',
            label: 'Regimen Fiscal Receptor',
            options: {
                customBodyRender: (value) => {
                    const newVal = value?.sort((a, b) => a.code - b.code);
                    return value && newVal.map((regime) => <Chip key={regime.code} label={regime.code} />);
                }
            }
        },
        {
            name: 'Acciones',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Stack direction="row">
                            <IconButton aria-label="edit" onClick={() => onEditClick(tableMeta.rowData)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => onDeleteClick(tableMeta.rowData)}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    );
                }
            }
        }
    ];

    const [isEditing, setIsEditing] = useState(false);

    // Confirm Action Dialog open
    const [cad, setCad] = useState({
        open: false
    });

    const onEditClick = (rowData) => {
        setIsEditing(true);
        const objectData = {
            id: rowData[0],
            code: rowData[1],
            desc: rowData[2],
            physical: rowData[3],
            moral: rowData[4],
            regimes: rowData[5].map((regime) => regime.code)
        };
        setInitialValues(objectData);
        handleClickOpen();
        setDisableUpload(true);
    };

    const onDeleteClick = (rowData) => {
        console.log(rowData);
        setCad({
            open: true,
            ids: [rowData[0]],
            text: 'Confirma que deseas eliminar el uso',
            code: rowData[1]
        });
    };

    const deleteData = async () => {
        try {
            if (isTokenExpired()) {
                await refreshToken();
            }
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/cfdiUse/delete',
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`
                },
                data: {
                    ids: cad.ids
                }
            };
            const res = await axios(config);
            console.log(res);
        } catch (error) {
            console.log(error.message);
            // if (error.response.data.error === 'JWT expirado') {
            //     refreshToken();
            //     deleteData();
            // }
            notify('error', 'Hubo un error y el registro no fue eliminado');
        } finally {
            setCad({ open: false });
            getUses();
        }
    };

    const [data, setData] = useState([{}]);

    const options = {
        // selectableRowsHideCheckboxes: true,
        // selectableRowsOnClick: true,
        filterType: 'textField',
        responsive: 'simple',
        tableBodyHeight: '50vh',
        // tableBodyMaxHeight: '100vh',
        sortOrder: {
            name: 'code',
            direction: 'asc'
        },
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex]._id); // array of all ids to to be deleted
            setCad({
                open: true,
                text: 'Confirma que deseas eliminar multiples usos',
                code: '',
                ids: idsToDelete
            });
        }
    };

    //New Regime modal state
    const [open, setOpen] = useState(false);

    const [initialValues, setInitialValues] = useState({
        code: '',
        desc: '',
        physical: false,
        moral: false,
        regimes: []
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setInitialValues({
            code: '',
            desc: '',
            physical: false,
            moral: false,
            regimes: []
        });
        setOpen(false);
        setDisableUpload(false);
        clearFile();
        setProgress(0);
        setIsEditing(false);
    };

    // Alert errors on response
    // const [responseError, setResponseError] = useState({
    //     error: false,
    //     type: '',
    //     severity: '',
    //     title: '',
    //     instructions: ''
    // });

    // Backdrop for onSumbit (loading screen)
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };

    // Form validation and handling events
    const [selectedFile, setSelectedFile] = useState(null);
    const [disableUpload, setDisableUpload] = useState(false);
    const [disableForm, setDisableForm] = useState(false);

    const validate = (values) => {
        // Validate if any field in the form is filled to disable upload button
        if (values.code || values.desc || values.moral || values.physical || values.regimes) {
            setDisableUpload(true);
        } else {
            setDisableUpload(false);
        }
        let errors = {};

        // Validate if at least one swith is true
        if (!values.moral && !values.physical) {
            errors.personType = 'Debe haber al menos un tipo de persona';
        }
        return errors;
    };

    const clearFile = () => {
        setSelectedFile(null);
        setDisableForm(false);
    };

    const handleFileChange = (event, setErrors) => {
        // Locating the file on a state and immediately cleaning the input file
        setSelectedFile(event.target.files[0]);
        setDisableForm(true);
        event.target.value = null;
        // Resetting form errors given that theres no need to show errors on a disabled form
        setErrors({});
    };

    // Success notification
    const notify = (type, message) => {
        toast[type](message);
    };

    const getUses = async () => {
        try {
            if (isTokenExpired()) {
                await refreshToken();
            }
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/cfdiUse',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            };
            const res = await axios(config);
            if (res.data.ok) {
                setData(res.data.uses);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [regimes, setRegimes] = useState([]);

    const getRegimes = async () => {
        try {
            if (isTokenExpired()) {
                await refreshToken();
            }
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/regime',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            };
            const res = await axios(config);
            if (res.data.ok) {
                const sortedRegimes = res.data.regimes.sort((a, b) => a.code - b.code);
                setRegimes(sortedRegimes);
                const codes = sortedRegimes.map((regime) => ({ code: regime.code, desc: regime.desc }));
                setCodes(codes);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [codes, setCodes] = useState([]);

    // Hydrate table
    useEffect(() => {
        getUses();
        getRegimes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {/*  ---------------- Table ----------------  */}
            <Table title={<h2>Lista de uso de CFDI</h2>} data={data} columns={columns} options={options} />

            {/* ----------------- Confirm Action Dialog ---------------- */}
            <CAD open={cad.open} type="error" confirmAction={deleteData} cancelAction={() => setCad({ open: false })}>
                <Typography>{`${cad.text} ${cad.code}`}</Typography>
            </CAD>

            {/*  ---------------- Add Button ----------------  */}
            <Fab color="primary" aria-label="add" onClick={handleClickOpen} sx={{ position: 'fixed', bottom: 25, right: 30 }}>
                <AddIcon />
            </Fab>

            <div>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            {/*  ---------------- New CFDIUse modal ----------------  */}
            <Modal
                open={open}
                handleClose={handleClose}
                title={isEditing ? 'Editar Uso' : 'Nuevo Uso'}
                disableSubmit={!disableForm && !disableUpload}
                submitText={!disableForm ? 'Guardar' : 'Cargar Archivo'}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={
                        !selectedFile &&
                        Yup.object().shape({
                            code: Yup.string()
                                .typeError('El código debe ser sólo números')
                                .max(6, 'El código debe ser de máximo 6 caracteres')
                                .min(1, 'El código debe ser de mínimo 1 carácter')
                                .required('El código es requerido'),
                            desc: Yup.string()
                                .max(150, 'La descripción debe ser de máximo 150 caracteres')
                                .required('La descripción es requerida'),
                            regimes: Yup.array()
                                .min(1, 'Debe seleccionar al menos una opción')
                                .required('Debe seleccionar al menos una opción')
                        })
                    }
                    validate={!selectedFile && validate}
                    onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
                        handleOpenBackdrop();

                        let data = disableForm && selectedFile ? selectedFile : { ...values };
                        data.regimes = regimes.filter((regimes) => values.regimes.includes(regimes.code));
                        const endpoint = disableForm && selectedFile ? '/api/v1/cfdiUse/upload' : '/api/v1/cfdiUse';

                        const formData = new FormData();
                        formData.append('file', selectedFile);

                        try {
                            if (isTokenExpired()) {
                                await refreshToken();
                            }
                            const config = {
                                onUploadProgress: (progressEvent) => {
                                    const percent = (progressEvent.loaded / progressEvent.total) * 100;
                                    setProgress(percent);
                                },
                                url: process.env.REACT_APP_SERVER_HOST + endpoint,
                                method: isEditing ? 'PATCH' : 'POST',
                                headers: {
                                    Authorization: `Bearer ${getToken()}`
                                },
                                data: disableForm ? formData : data
                            };

                            const res = await axios(config);

                            if (res.data.ok) {
                                if (disableUpload && !isEditing) {
                                    setData((prev) => [...prev, { ...values }]);
                                }
                                handleCloseBackdrop();
                                notify(
                                    'success',
                                    disableForm
                                        ? `El archivo fue cargado con éxito! 
                                    ${res.data.message}`
                                        : isEditing
                                        ? 'Uso actualizado!'
                                        : 'Uso creado con éxito!'
                                );
                                handleClose(resetForm);
                            }

                            console.log(res);
                        } catch (error) {
                            console.log(error);
                            switch (error.response.data.error) {
                                case 'El código ya existe':
                                    setErrors({
                                        code: 'El código debe de ser único y este ya existe'
                                    });
                                    break;

                                default:
                                    break;
                            }
                            notify('error', error.response.data.error);
                            console.log(error);
                        } finally {
                            handleCloseBackdrop();
                            setSubmitting(false);
                            setIsEditing(false);
                            getUses();
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setErrors }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            {/* {responseError.error && (
                                <Alert severity={responseError.severity}>
                                    <AlertTitle>{responseError.title}</AlertTitle>
                                    {responseError.instructions}
                                </Alert>
                            )} */}
                            <FormControl fullWidth error={Boolean(touched.code && errors.code)} sx={{ marginY: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-code">Código</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-code"
                                    type="text"
                                    value={values.code}
                                    name="code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Código"
                                    disabled={disableForm || isEditing}
                                    inputProps={{}}
                                />
                                {touched.code && errors.code && (
                                    <FormHelperText error id="standard-weight-helper-text-code">
                                        {errors.code}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth error={Boolean(touched.desc && errors.desc)} sx={{ marginY: 1 }}>
                                <InputLabel htmlFor="outlined-adornment-desc">Descripción</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-desc"
                                    type="text"
                                    value={values.desc}
                                    name="desc"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Descripción"
                                    disabled={disableForm}
                                    inputProps={{}}
                                />
                                {touched.desc && errors.desc && (
                                    <FormHelperText error id="standard-weight-helper-text-desc">
                                        {errors.desc}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                component="fieldset"
                                fullWidth
                                error={Boolean(touched.moral && touched.physical && errors.personType)}
                            >
                                <FormLabel component="legend">Aplica para tipo de persona</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={values.physical} onChange={handleChange} name="physical" />}
                                        label="Física"
                                        disabled={disableForm}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={values.moral} onChange={handleChange} name="moral" />}
                                        label="Moral"
                                        disabled={disableForm}
                                    />
                                </FormGroup>
                                {errors.personType && (
                                    <FormHelperText error id="standard-weight-helper-text-personType">
                                        {errors.personType}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth sx={{ marginY: 1 }}>
                                <InputLabel htmlFor="multiple-chip">Regímenes</InputLabel>
                                <Select
                                    id="multiple-chip"
                                    multiple
                                    name="regimes"
                                    label="Regímenes"
                                    value={values.regimes}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Regimenes" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {codes.map((code) => (
                                        <MenuItem key={code.code} value={code.code} style={getStyles(code.code, values.regimes, theme)}>
                                            {code.code + ' - ' + code.desc}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.regimes && (
                                    <FormHelperText error id="standard-weight-helper-text-personType">
                                        {errors.regimes}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                            <Divider
                                sx={{
                                    mt: 3,
                                    mb: 2
                                }}
                            />
                            {!isEditing && (
                                <FormControl>
                                    <FormLabel component="legend" sx={{ mb: 2 }}>
                                        O si prefieres puedes cargar un archivo .xslx con el{' '}
                                        <a href={file} download="Formato Uso del CFDI.xlsx">
                                            formato
                                        </a>{' '}
                                        requerido
                                    </FormLabel>
                                    <Button variant="contained" component="label" sx={{ textTransform: 'none' }} disabled={disableUpload}>
                                        Subir archivo .xslx
                                        <input
                                            type="file"
                                            hidden
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(event) => handleFileChange(event, setErrors)}
                                        />
                                    </Button>
                                    <Box>
                                        {selectedFile && (
                                            <Stack justifyContent="center" alignItems="center" direction="row" sx={{ mt: 2, mb: 2 }}>
                                                <AttachFileIcon fontSize="small" />
                                                <FormHelperText sx={{ fontSize: '1rem' }}>{selectedFile.name}</FormHelperText>
                                                {progress > 0 ? (
                                                    <CircularProgressWithLabel value={progress} />
                                                ) : (
                                                    <ClearIcon sx={{ cursor: 'pointer' }} fontSize="15px" onClick={clearFile} />
                                                )}
                                            </Stack>
                                        )}
                                    </Box>
                                </FormControl>
                            )}
                            <DialogActions>
                                <Button onClick={handleClose}>Cancelar</Button>
                                <Button type="submit" disabled={!disableForm && !disableUpload}>
                                    {!disableForm ? 'Guardar' : 'Cargar Archivo'}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function getStyles(name, regimeCode, theme) {
    regimeCode = regimeCode.map((regime) => regime.code);

    // console.log(name);
    // console.log(regimeCode);
    // console.log(regimeCode.indexOf(name));
    return {
        fontWeight: regimeCode.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

export default CFDIUse;
