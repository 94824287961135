import React from 'react';
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

CAD.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    confirmAction: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['error', 'info', 'inherit', 'primary', 'secondary', 'success', 'warning']).isRequired
};

/**
 * Este componente representa un dialogo de confirmación para una acción.
 * @param {Object} props - Las propiedades del componente.
 * @param {boolean} props.open - El estado del Dialog True = Abierto / False = Cerrado.
 * @param {Function} props.confirmAction - Función a ejecutar cuando se haga click en confirmar.
 * @param {Function} props.cancelAction - Función a ejecutar cuando se haga click en cancelar.
 */

export default function CAD({ open = false, children, confirmAction, cancelAction, type = 'info' }) {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={cancelAction}>
            <DialogTitle variant="h2">Confirmar acción</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={cancelAction}>
                    Cancelar
                </Button>
                <Button variant="contained" color={type} onClick={confirmAction}>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
