// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
    import logoDark from 'assets/images/logo-dark.svg';
    import logo from 'assets/images/logo.svg';
 *
 */

// import logoBlack from 'assets/images/logo-black.svg';
// import logoColor from 'assets/images/logo-color.svg';
import logoNoBackground from 'assets/images/logo-no-background.svg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <img src={logoNoBackground} alt="Super Validador" width="170" />
    );
};

export default Logo;
