import { create } from 'zustand';
import axios from 'axios';

export const useCompanyStore = create((set) => ({
    data: null,
    setGlobalCompanies: async (value) => {
        set(() => ({
            data: value
        }));
    },
    setActiveCompany: async (value) => {
        set(() => ({
            activeCompany: value
        }));
    },
    getActiveCompany: () => {
        return useCompanyStore.getState().activeCompany;
    },
    getCompanies: async (isTokenExpired, refreshToken, getToken) => {
        try {
            if (isTokenExpired()) {
                await refreshToken();
            }
            const config = {
                url: process.env.REACT_APP_SERVER_HOST + '/api/v1/company',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            };
            const res = await axios(config);
            if (res.data.ok) {
                const sortedCompanies = res.data.companies.sort((a, b) => a.code - b.code);
                set(() => ({
                    data: sortedCompanies
                }));
                const storedOptionExist = localStorage.getItem('lastSelectedValue');
                const storedOption = storedOptionExist !== 'undefined' ? JSON.parse(storedOptionExist) : null;
                if (storedOption) {
                    // Verificar si la opción almacenada está en las opciones
                    const selectedOptionExists = sortedCompanies.some((option) => option._id === storedOption._id);
                    if (selectedOptionExists) {
                        // setActiveCompany(storedOption);
                    } else {
                        // Limpiar el valor almacenado si no se encuentra en las opciones
                        localStorage.removeItem('lastSelectedValue');
                    }
                }
                return sortedCompanies;
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}));
