import React from 'react';
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

CAD.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    submitText: PropTypes.string.isRequired,
    disableSubmit: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default function CAD({
    open = false,
    title,
    children,
    submitText = 'Guardar',
    disableSubmit = false,
    handleClose,
    fullWidth = false,
    maxWidth = 'md'
}) {
    return (
        <Dialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
            <DialogTitle variant="h2">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit" disabled={disableSubmit}>
                    {submitText}
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}
