import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useUserStore } from 'store/userStore';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// import for notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    const { logout } = useUserStore();

    const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

    useEffect(() => {
        const handleUserBlur = () => {
            setLastInteractionTime(Date.now());
        };

        const handleUserFocus = () => {
            const currentTime = Date.now();
            const sessionTimeoutDuration = 60 * 60 * 1000; // 1 hora en milisegundos

            if (currentTime - lastInteractionTime > sessionTimeoutDuration) {
                // Aquí puedes implementar la lógica para cerrar la sesión,
                // por ejemplo, redirigir a la página de inicio de sesión
                logout();
            }
        };

        window.addEventListener('blur', handleUserBlur);
        window.addEventListener('focus', handleUserFocus);

        return () => {
            window.removeEventListener('blur', handleUserBlur);
            window.removeEventListener('focus', handleUserFocus);
        };
    }, [lastInteractionTime]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <ToastContainer autoClose={2500} />
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
