import { useRoutes, Navigate, useLocation } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const routeResult = useRoutes([MainRoutes, AuthenticationRoutes, MaintenanceRoutes]);
    const location = useLocation();

    const authPaths = AuthenticationRoutes.children.map((route) => route.path);

    if (localStorage.user) {
        // Check if route exist
        if (!routeResult) {
            // console.log('No routes found');
            // Adding state for reference
            return <Navigate to="/404" replace state={{ from: 'router' }} />;
        } else {
            //If route exists verify if its redirected by this router
            if (location.state?.from === 'router') {
                console.log(routeResult);
                console.log('route exists');
                return routeResult;
            }
        }
        // Checking if user is authenticated
        // console.log('is authenticated');
        return routeResult;
    } else {
        // console.log('not authenticated');
        // If user is not authenticated verify if she/he want to authenticate and evade a loop
        if (authPaths.includes(location.pathname)) {
            // console.log('but about to');
            return routeResult;
        }
        return <Navigate to="/auth/login" replace state={{ from: 'router' }} />;
    }
}
